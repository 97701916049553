body {
    background: #373737;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logopage {
    padding: 5px;
    width: 300px;
}

.footer-clean {
    padding: 10px 0;
    background-color: rgb(15, 15, 15);
    color: #4b4c4d;
    margin-top: 1rem;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer-clean h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
}

.footer-clean ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
}

.footer-clean ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.8;
}

.footer-clean ul a:hover {
    opacity: 1;
    color: #F28121;
}

.footer-clean .item.social {
    text-align: right;
}

@media (max-width:767px) {
    .footer-clean .item {
        text-align: center;
        padding-bottom: 5px;
    }
    .footer-clean {
        margin-top: 300px;
    }
    .footer-clean .item.social>a {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .footer-clean .item.social {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .socialhide {
        display: none;
    }
}

.footer-clean .item.social>a {
    font-size: 16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #545353;
    margin-left: 10px;
    margin-top: 22px;
    color: inherit;
    opacity: 0.75;
}

.footer-clean .item.social>a:hover {
    opacity: 0.9;
    color: #F28121;
    border: 1px solid #F28121;
}

@media (max-width:991px) {
    .footer-clean .item.social>a {
        margin-top: 40px;
    }
}

@media (max-width:767px) {
    .footer-clean .item.social>a {
        margin-top: 10px;
    }
}

.footer-clean .copyright {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 13px;
    opacity: 0.6;
}